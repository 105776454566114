:root {
    --color-header: #246eb9;
    --color-text: white;
    /* --color-background-sidpanel: rgb(222, 214, 214); */
    --color-background-sidpanel: rgb(193, 193, 193);
    --color-background: white;
    --color-border: rgba(175, 166, 166, 0.87);
    --color-background-card: #095dc453;
    --color-card: #e8f1f2;
    --color-menu-button: #2b9eb3;
    --color-primary: #44af69;
    --color-warning: #f8333c;
    --color-secondary: #fcab10;
}
