@import "Atomic.css";
@import "Colors.css";

.header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: var(--header-height);
    text-align: center;
    background-color: var(--color-header);
    color: var(--color-text);
    position: fixed;
}

h2 {
    padding: 0;
    margin: 0;
    align-self: center;
}

.footer {
    position: fixed;
    bottom: 0;
    height: 3em;
}

.gameRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1em;
}

.kniffelImage {
    height: 2.5rem;
}

.kniffelButton {
    width: 7em;
    height: 3em;
    border: none;
    border-radius: 5px;
    font-size: 1em;
}

.kniffelBlock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15em, 25em));
    padding-bottom: 1em;
    overflow-y: auto;
    padding-top: 3em;
    animation-name: appear;
    animation-duration: 1s;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.menuView {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
}

.flexSpaceAround {
    display: flex;
    justify-content: space-around;
}

.resultRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--color-card);
    margin-bottom: 1em;
    height: 2em;
    font-size: 1.25em;
}

.lobbyView {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
}

.buttonGroup {
    margin-top: 1em;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.lobbyInput {
    border: 2px solid var(--color-header);
    border-radius: 5px;
    height: 3em;
    width: 80%;
    font-size: 16px;
}

.lobbyListItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3em;
    padding-left: 1em;
    border-bottom: 1px solid var(--color-border);
    font-size: 1em;
}

.lobbyBlock {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.resultView {
    padding-top: var(--header-height)
}
