.view{
    max-width: 500px;
    width: 100%;
    height: 80vh;
    padding-top: 3em;
}

.numPad{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 50vh;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 500px;
}

.input{
    width: 100%;
    height: 16px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: center;
    font-size: 2em;
}

.divLabel{
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSubmit{
    font-size: 1.5em;
    grid-area: 4 / 1 / 5 / 5;
}
