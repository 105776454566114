.arrow-button {
    background-color: var(--color-header);
    color: var(--color-text);
    min-width: 4rem;
    height: 3rem;
}

.arrow-icon{
    width: 32px;
    height: 32px;
}