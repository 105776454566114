@import "Colors.css";

:root {
  --border-radius: 5px;
  --padding: 1em;
  --margin: 1em;
  --font-size: 16px;
  --header-height: 3em;
}

html {
  font-size: var(--font-size);
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--padding);
  min-width: 8em;
  font-size: 1em;
}

#numButton {
  min-width: 1em;
  background-color: var(--color-background-sidpanel);
}

#bonus-check-icon {
  width: 2rem;
}

.image-wrapper {
  width: 128px;
  display: flex;
  justify-content: center;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: var(--margin);
}

.menuBody {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: var(--padding);
}
